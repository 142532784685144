// Version 1.2 S
// alert('Hello FROM repmod_student_rpt-2002');
// alert("repmod_student_rpt   - 501-IN-PACKS, rptoptionselected  = " + rptoptionselected );

// disable all options
function disableAllActivityOptions(form, optActivity) {
  console.log("got here - 1001");
  for (var i = 0; i < optActivity.length; i++)
      optActivity[i].disabled = true ;
}

// enableable all options
function enableActivityOptions(form, optActivity, rptoptionselected ) {
//  console.log("got here - 2001");
//   alert("repmod_student_rpt   - 501, enableActivityOptions  " );

    if ( rptoptionselected.includes("login") ) {
      for(var i = 0; i < optActivity.length; i++) {
         var actoptiontest = (optActivity[i].label).toLowerCase();
         if  ( actoptiontest.includes("login") ) {
           optActivity[i].disabled = false ;
         }
      }

      optActivity[0].disabled = false ;  // all
    } else if ( rptoptionselected.includes("quiz") ) {
      for(var i = 0; i < optActivity.length; i++) {
         var actoptiontest = (optActivity[i].label).toLowerCase();
         if  ( actoptiontest.includes("quiz") ) {
           optActivity[i].disabled = false ;
         }
      }

    } else if ( rptoptionselected.includes("learning") ) {
      for(var i = 0; i < optActivity.length; i++) {
         var actoptiontest = (optActivity[i].label).toLowerCase();
         if  ( actoptiontest.includes("learning") ) {
           optActivity[i].disabled = false ;
         }
      }


    }
}

function checkFormBeforeEnableSubmit(form, reportSelect, activitySelect,
           optionReport, optionActivity, submitbutton) {


  var rindex =  reportSelect.selectedIndex;
  var rptoptionselected  = (optionReport[rindex].label).toLowerCase();

  var actindex =  activitySelect.selectedIndex;
  var actoptionselected  = (optionActivity[actindex].label).toLowerCase();

  if ( rptoptionselected.includes("login") && actoptionselected.includes("login") )  {
      submitbutton.disabled = false;
  } else if ( rptoptionselected.includes("login") && actoptionselected.includes("all") )  {
    submitbutton.disabled = false;
  } else if ( rptoptionselected.includes("quiz") && actoptionselected.includes("quiz") )  {
    submitbutton.disabled = false;
  } else if ( rptoptionselected.includes("learning") && actoptionselected.includes("learning") )  {
    submitbutton.disabled = false;
  }

// temp
submitbutton.disabled = false
}



 $( document ).on('turbolinks:load', function() {
  console.log("got here - repmod turbolinks");


  var form = document.querySelector('form[id^="new_std_rpt"]');

//  console.log("got here - repmod turbolinks - 101");

  if ( form  === null) {
    console.log("got here - repmod turbolinks - 102 - is null");
    return;
  }
// console.log("got here - repmod turbolinks - 102");

// alert('Hello FROM new_std_rpt - 1001');

  var submitbutton = document.querySelector('input[name ="commit"]');

  const reportSelect = document.querySelector('select[id^="student_rpt_id"]');
  const activitySelect= document.querySelector('select[id^="student_rpt_eventid"]');

  const optionReport   = reportSelect.getElementsByTagName("option")
  const optionActivity = activitySelect.getElementsByTagName("option");

// temp
//  submitbutton.disabled = false;

  var rindex =  reportSelect.selectedIndex;
  var rptoptionselected  = (optionReport[rindex].label).toLowerCase();

  disableAllActivityOptions(form, optionActivity);

  enableActivityOptions(form, optionActivity, rptoptionselected );


  activitySelect.addEventListener('change', function() {
    checkFormBeforeEnableSubmit(form, reportSelect, activitySelect,
               optionReport, optionActivity, submitbutton);
    });


  reportSelect.addEventListener('change', function() {
      submitbutton.disabled = true;
      var rindex =  reportSelect.selectedIndex;
      var rptoptionselected  = (optionReport[rindex].label).toLowerCase();

      disableAllActivityOptions(form, optionActivity);

      enableActivityOptions(form, optionActivity, rptoptionselected );

    });




  });
