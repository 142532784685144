/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb



// try {
// //  require("@rails/ujs").start()
//   require("@rails/ujs")
// }
// catch(ex) {
//   console.log("ERROR IN LOADING RAILS UJS");
//   console.log(ex);
// }

require("@rails/ujs")
//export const rails_ujs = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

require("popper.js")

require("jquery")
require("@nathanvda/cocoon")


// require jquery
// var jQuery = require("jquery")


// require popper
// require("popper")

// require bootstrap
require("bootstrap")

// require pace
require("pace")



// require simplemde
//require("simplemde")
// import * as SimpleMDE from "simplemde";
//
// // require katex
// require("katex")

// require auto-render-min
require("auto-render-min")

// require cocoonis:open
//require("cocoon")

// require_tree .
// require("tree")

// Learner

require("activestorage")

// require bootstrap-sprockets
// not needed as bootstrap already included
// require("bootstrap-sprockets")

// require image-picker
// require("image-picker")

//  require pdfjs-dist
// require("pdfjs-dist")
// import * as pdfjsLib from 'pdfjs-dist';

// require pdfjs-dist/build/pdf.worker.js

// require ahoy
// require("ahoy")
import ahoy from "ahoy.js";

// require lessons
// these would be under app/javascript/learner
require("learner/lessons")
require("quizzer/quizzes")
require("quizzer/questions")


// require("repmod_student_rpt")
require("repmod/repmod_student_rpt")


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

// alert('Hello World from Webpacker-1')
