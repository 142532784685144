import PropTypes from 'prop-types';
import React from 'react';

export default class serverFooter extends React.Component {

  render() {
    return (
      <footer className="server-footer-container row">
        <div className="server-logos-container">
          <p>COPYRIGHT @ 2020 Phoenix Computer Academy</p>
          <div>
            <a href="http://www.instagram.com">
              <img src={this.props.instagram} alt="logo" className="social-media-logos" />
            </a>
            <a href="https://twitter.com/phxcompacademy">
              <img src={this.props.twitter} alt="logo" className="social-media-logos" />
            </a>
            <a href="https://www.facebook.com/phoenixcomputeracademy/">
              <img src={this.props.facebook} alt="logo" className="social-media-logos" />
            </a>
          </div>
          <div className="made-in">
            <p>Connect with us on Social Media!</p>
          </div>
        </div>
        <div className="server-links-container">
          <div className="server-link-column non-mobile">
            <a href="/users/sign_in">
              <p>Log In</p>
            </a>
            <a href="/users/sign_up">
              <p>Create an Account</p>
            </a>
            <a href="/users/sign_in">
              <p>My Profile</p>
            </a>
            <a href="/users/sign_out">
              <p>Sign Out</p>
            </a>
          </div>
          <div className="server-link-column">
            <p><a href= "http://www.phxcomputeracademy.com"> About </a></p>
            <p><a href= "http://www.phxcomputeracademy.com"> Contact </a></p>
          </div>
        </div>
      </footer>
    )
  }
}
