// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.


$(document).on('turbolinks:load', function() {

    document.getElementsByTagName("input").value = "";
    //$("#select_all_questions").addEventListener('click', function() {
    $("#select_all_questions").on("click", function() {
        console.log('in function');
        if ($('#select_all_questions:checkbox').prop('checked')) {
            $("div#questions_search_result :checkbox").prop('checked', true);
        }
        else {
            $("div#questions_search_result :checkbox").prop('checked', false);
        }
    });
    $("#add_questions").click(function(){
        var table = document.getElementById("selected_questions_table");

        $.each($("div#questions_search_result :checkbox:checked"),
            function () {
                var checkedRow = $(this).closest("tr");
                var row = table.insertRow();
                $.each(checkedRow.find("td:not(:first)"), function () {
                    var cell = row.insertCell();
                    $(cell).html($(this).html());
                });
                var cell = row.insertCell();
                $(cell).html(
                    '<input type="hidden" name="quiz[question_ids][]" id="quiz_question_ids_" value='+ $(this)["0"].value +  '>' +
                    '<input type="checkbox" name="quiz[question_ids][]" id="quiz_remove_question" value='+ $(this)["0"].value +  '>'
                    );
                //checkedRow.remove();
            });

        $('div#questions_search_result :checkbox').removeAttr('checked');
    });

    $("#remove_questions").click(function(){
        var table = document.getElementById("selected_questions_table");

        $.each($("div#existing_questions :checkbox:checked"),
            function () {
                var toRemoveRow = $(this).closest("tr");
                toRemoveRow.remove();
            });

        $('div#existing_questions :checkbox').removeAttr('checked');
    });
  });
