import PropTypes from 'prop-types';
import React from 'react';
import Footer from './Footer'
import LandingHeader from './LandingHeader'
import FrontPageSlider from './FrontPageSlider'

export default class Landing extends React.Component {
  render() {
    return (
      <div className="landing-container">
        <div className="landing-dashboard">
          <LandingHeader signed_in= {this.props.props.signed_in}/>
          <div className="dashboard-container">
            <FrontPageSlider slider_images={this.props.props.slider_images}/>
            <div className="dashboard-content">
              <div className="dashboard-title">

                <img src={this.props.props.logo} alt="logo"  className="title-logo"/>
                <h1>PCA Learn v2</h1>
              </div>
              <div className="dashboard-text">
                <p>Welcome to the e-learning site of Phoenix Computer Academy.</p>
              </div>
              <div className="dashboard-button-container">
                <a href="/dashboard/">
                  <div className="dashboard-button">
                    <p>GET STARTED</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer instagram={this.props.props.instagram} twitter={this.props.props.twitter} facebook={this.props.props.facebook} />
      </div>
    )
  }
}
