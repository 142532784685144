// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
import * as SimpleMDE from "simplemde";
try {
  require("@rails/ujs").start();
}
catch (e) {
  console.log("rails ujs already started");
}

// import { rails_ujs } from "packs/application.js"
// rails_ujs.start()
import renderMathInElement from "katex/dist/contrib/auto-render.js";
//require("katex")
require("image-picker");
import * as pdfjsLib from 'pdfjs-dist';
import ahoy from "ahoy.js";

document.addEventListener("turbolinks:load", function(){
  $("video").on('play',function(){
    var element = $(this)[0];
    try { //will work for new videos that have been inserted
      var medid = element.attributes['medid'].nodeValue;
      ahoy.track('$video_play', {url: window.location.href, src:element['src'], id: medid, name: element.attributes['name'].value});
    }
    catch (error) {
      ahoy.track('$video_play', {url: window.location.href, src:element['src']});
    }
  });

  $("video").on('pause',function(){
    var element = $(this);
    try { //will work for new videos that have been inserted
      var medid = element.attributes['medid'].nodeValue;
      ahoy.track('$video_pause', {url: window.location.href, src:element[0]['src'], id: medid,name: element[0].attributes['name'].value});
    }
    catch (error) {
      ahoy.track('$video_pause', {url: window.location.href, src:element[0]['src']});
    }
  });

  $("video").on('ended',function(){
    var element = $(this);
    try { //will work for new videos that have been inserted
      var medid = element.attributes['medid'].nodeValue;
      ahoy.track('$video_end', {url: window.location.href, src:element[0]['src'], id: medid, name: element[0].attributes['name'].value});
    }
    catch (error) {
      ahoy.track('$video_end', {url: window.location.href, src:element[0]['src']});
    }
  });
});

function generateRemoteErrorHTML(errors) {
  var str = "<div id='error_explanation'>"
  str += "<h6>" + errors.length + "error(s) prohibited this media item from being saved:</h6>"
  str += "<ul>"

  errors.forEach(function(err) {
    str += '<li>'+ err + '</li>';
  });

  str += '</ul> </div>';

  return str

}

function renderPDF(url, canvas) {
    var options = options || { scale: 1 };
    //var canvas = document.createElement('canvas');
    var pdfDoc = null,
        pageNum = 1,
        pageRendering = false,
        pageNumPending = null,
        scale = 0.8,
        canvas = canvas,
        ctx = canvas.getContext('2d');
    //canvasContainer.appendChild(canvas);

    function renderPage(num) {
      pageRendering = true;
      pdfDoc.getPage(num).then(function(page) {
        var viewport = page.getViewport({scale: scale});
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);

        // Wait for rendering to finish
        renderTask.promise.then(function() {
          pageRendering = false;
          if (pageNumPending !== null) {
            // New page rendering is pending
            renderPage(pageNumPending);
            pageNumPending = null;
          }
        });
      });
    }

    /**
     * If another page rendering in progress, waits until the rendering is
     * finised. Otherwise, executes rendering immediately.
     */
    function queueRenderPage(num) {
      if (pageRendering) {
        pageNumPending = num;
      } else {
        renderPage(num);
      }
    }

    function onPrevPage() {
      if (pageNum <= 1) {
        return;
      }
      pageNum--;
      queueRenderPage(pageNum);
    }
    document.getElementById('slides_prev').addEventListener('click', onPrevPage);


    function onNextPage() {
        if (pageNum == 1) {
          ahoy.track('$slides_start', {url: window.location.href, src: url, pageno: pageNum, pagetotal: pdfDoc.numPages });
         }

        if (pageNum >= pdfDoc.numPages) {
        return;
        }

        pageNum++;

        if ( ( pageNum >= 2) && (pageNum < pdfDoc.numPages ) ) {
          ahoy.track('$slides_next', {url: window.location.href, src: url, pageno: pageNum, pagetotal: pdfDoc.numPages });
          }


        if (pageNum == pdfDoc.numPages ) {
           ahoy.track('$slides_end', {url: window.location.href, src: url, pageno: pageNum, pagetotal: pdfDoc.numPages });
           }



      queueRenderPage(pageNum);
    }
    document.getElementById('slides_next').addEventListener('click', onNextPage);

    canvas.addEventListener('click', onNextPage);



    pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
      pdfDoc = pdfDoc_;
      renderPage(pageNum);
    });

    $(document).keydown(function(e){
        if (e.which == 37) {
           onPrevPage()
           return false;
        }
        if (e.which == 39) {
           onNextPage()
           return false;
        }
    });

  }

  document.addEventListener("DOMContentLoaded", function() {
    renderMathInElement(document.body, {});
  });


  document.addEventListener("turbolinks:load", function(){
    console.log("lessons.js loaded");
  if ($("main").hasClass("lesson_edit")) {
    $('#mediaLibPicker').imagepicker({show_label: true});
    $('#videoLibPicker').imagepicker({show_label: true});
    const toolbar = [
          'bold', 'italic', 'heading', '|',
          'code', 'quote', 'unordered-list', 'ordered-list', '|',
          'link',
          {
            name: 'image',
            action: addImageFromGallery,
            className: 'fa fa-picture-o',
            title: 'Insert Image',
          },
          {
            name: 'video',
            action: addVideoFromGallery,
            className: 'fa fa-video-camera',
            title: 'Insert Video',
          },
          'table', '|',
          'preview', 'side-by-side', 'fullscreen', '|',
          '|', // Separator
    ];

    function addVideoFromGallery(editor) {
      var videoPicker = document.getElementById('videoLibPicker');
      var cm = editor.codemirror;
      $('#vidModal').modal('show');
      var insertButton = document.getElementById("selectVidButton")
      insertButton.onclick = function() {
        var picked = videoPicker.options[videoPicker.selectedIndex];
        //console.log(picked.attributes);
        var pos = cm.getCursor();
        cm.setSelection(pos, pos);
        cm.replaceSelection("<video autobuffer=\"autobuffer\" controls=\"controls\" class=\"lesson_video\" name=\"" + picked.attributes["value"].nodeValue + "\" medid=\"" + picked.attributes["medid"].nodeValue + "\" src=\"" + picked.attributes["video-src"].nodeValue + "\" ></video>")
        //cm.replaceSelection("<video autobuffer=\"autobuffer\" controls=\"controls\" class=\"lesson_video\" src=\"" + picked.attributes["video-src"].nodeValue + "\" ></video>")
        $('#vidModal').modal('hide');
      }
    }

    function addImageFromGallery(editor) {
      var imagePicker = document.getElementById('mediaLibPicker');
      var cm = editor.codemirror;
      $('#myModal').modal('show');
      var insertButton = document.getElementById("selectImgButton")
      insertButton.onclick = function() {
        var picked = imagePicker.options[imagePicker.selectedIndex];
        //console.log(picked.attributes["data-img-src"].nodeValue);
        var pos = cm.getCursor();
        cm.setSelection(pos, pos);
        cm.replaceSelection("![img](" + picked.attributes["data-img-src"].nodeValue + ")\n");
        $('#myModal').modal('hide');
      }
    }

    var simplemde = new SimpleMDE({
      element: $('.simplemde')[0],
      autoDownloadFontAwesome: false,
      spellChecker:false,
      toolbar: toolbar
    });

    $(imageUploadForm).on("ajax:success", function(event){
      console.log("got to form");
      var data;
      var status;
      var xhr;
      [data, status, xhr] = event.detail

      if (data.errors == false) {
        $(imgUploadErrors).html('');

        var option = document.createElement("option");
        option.value = data.medium_name;
        option.text = data.medium_name;
        option.setAttribute("data-img-src", data.url);

        var select = document.getElementById("mediaLibPicker");
        select.appendChild(option);

        var editor = simplemde;
        var cm = editor.codemirror;

        var pos = cm.getCursor();
        cm.setSelection(pos, pos);
        cm.replaceSelection("![img](" + data.url + ")\n");
        $('#myModal').modal('hide');
        $("#imageUploadForm")[0].reset();
        $("#mediaLibPicker").imagepicker({show_label: true});
      }
      else {
        var errorhtml = generateRemoteErrorHTML(data.medium_errors);
        var errorBox = document.getElementById("imgUploadErrors");
        errorBox.innerHTML = errorhtml;
      }
    });

    $(videoUploadForm).submit(function(e) {
      $('button[data-loading]').prop('disabled', true);
      $('button[data-loading]').find('.fa:first').attr('class', 'fa fa-refresh fa-spin');
    });

    $(videoUploadForm).on("ajax:success", function(event){
      var data;
      var status;
      var xhr;
      [data, status, xhr] = event.detail
      if (data.errors == false) {

        $(vidUploadErrors).html('');

        var option = document.createElement("option");
        option.value = data.medium_name;
        option.text = data.medium_name;
        option.setAttribute("data-img-src", data.screenshot);

        var select = document.getElementById("videoLibPicker");
        select.appendChild(option);

        var editor = simplemde;
        var cm = editor.codemirror;

        var pos = cm.getCursor();
        cm.setSelection(pos, pos);
        cm.replaceSelection("<video autobuffer=\"autobuffer\" controls=\"controls\" class=\"lesson_video\" src=\"" + data.url + "\" ></video>")
        $('#vidModal').modal('hide');
        $("#videoUploadForm")[0].reset();
        $("#videoLibPicker").imagepicker({show_label: true});
      }
      else {
        var errorhtml = generateRemoteErrorHTML(data.medium_errors);
        var errorBox = document.getElementById("vidUploadErrors");
        errorBox.innerHTML = errorhtml;
      }
      $('button[data-loading]').prop('disabled', false);
      $('button[data-loading]').find('.fa:first').attr('class', 'fa fa-sign-out');
    });


  }
  if (document.getElementById('lesson_slides')) {
    renderPDF($('#lesson_slides').attr('data-temp'), document.getElementById('lesson_slides'));
  }

  });

 $(document).ready(function() {
    $("video").bind("contextmenu",function(){
        return false;
        });
 } );
