import PropTypes from 'prop-types';
import React from 'react';
import Slider from "react-slick";

export default class LazyLoad extends React.Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      lazyLoad: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 1,
      autoplay: true,
      adaptiveHeight: true
    };
    return (<Slider {...settings}>
        {this.props.slider_images.map(image => (
            <div>
              <img src={image}/>
            </div>
        ))}
        </Slider>
    );
  }
}
