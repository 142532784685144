import PropTypes from 'prop-types';
import React from 'react';

export default class LandingHeader extends React.Component {
  render() {
    if (this.props.signed_in) {
      return (
        <header className="landing-Header">
          <div className="header-server-logo">
          </div>
          <div className="header-button-container">
            <a href="/users/sign_out" to="">
              <div className="landing-button signup">
                <p>Sign out</p>
              </div>
            </a>
          </div>
        </header>
      )
    }
    else {
      return (
        <header className="landing-Header">
          <div className="header-server-logo">
          </div>
          <div className="header-button-container">
            <a href="/users/sign_in">
              <div className="landing-button login">
                <p>Log in</p>
              </div>
            </a>
            <a href="/users/sign_up">
              <div className="landing-button signup">
                <p>Sign up</p>
              </div>
            </a>
          </div>
        </header>
      )
    }
  }
}
